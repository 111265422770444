import {Outlet} from 'react-router-dom';
// @mui
import {styled} from '@mui/material/styles';
//
import Header from './header';
import CookiesBanner from "../../utils/CookiesBanner";
import Footer from "./footer";

// ----------------------------------------------------------------------

const APP_BAR_MOBILE = 64;
const APP_BAR_DESKTOP = 92;

const StyledRoot = styled('div')({
	display: 'flex',
	minHeight: '100%',
	overflow: 'hidden',
	background: "rgb(249, 250, 251)"
});

const Main = styled('div')(({theme}) => ({
	overflow: 'auto',
	flexGrow: 1,
	minHeight: '100%',
	paddingTop: APP_BAR_MOBILE + 24,
	paddingBottom: theme.spacing(5),
	[theme.breakpoints.up('lg')]: {
		paddingTop: APP_BAR_DESKTOP + 24,
		paddingLeft: theme.spacing(2),
		paddingRight: theme.spacing(2),
	},
}));

// ----------------------------------------------------------------------

export default function BodyLayout() {
	return (
		<StyledRoot>
			<Header/>
			<CookiesBanner/>
			<Main>
				<Outlet/>
				<Footer/>
			</Main>
		</StyledRoot>
	);
}
