import {Helmet} from 'react-helmet-async';
// @mui
import {Container, Typography, Stack, List, ListItemText} from '@mui/material';

// ----------------------------------------------------------------------
export default function PrivacyPage() {
	const topics = [
		"What data do we collect?",
		"How do we collect your data?",
		"How will we use your data?",
		"How do we store your data?",
		"What are your data protection rights?",
		"What are cookies?", "How do we use cookies?",
		"What types of cookies do we use?",
		"How to manage your cookies",
		"Privacy policies of other websites",
		"Changes to our privacy policy",
		"How to contact us"
	]

	const rights = [
			"You have the right to request OmniunAI for copies of your presonal data. We may charge you a small fee for this service;",
			"You have the right to request that OmniumAI correct any information you believe is inaccurate. You also have the right to request OmniumAI to complete information you believe is incomplete;",
			"You have the right to request that OmniumAI erase your personal data, under certain conditions;",
			"You have the right to request that OmniumAI restric the processing of your personal data, under certain conditions;",
			"You have the right to object to OmniumAI's processing of you personal data, under certain conditions;",
			"You have the right to request that OmniumAI transfer the data that we have collected to another organization, or directly to you, under certain conditions."
		]

	return (
		<>
			<Helmet>
				<title> OmniumAI </title>
			</Helmet>
			<Container maxWidth="xl"
					   sx={{px: {xs: 1, md: 3}, py: 5}}>
				<Stack spacing={{xs: 3, md: 5, lg: 8}}>
					<div>
						<Typography variant={"h2"} paragraph>
							OmniumAI's Privacy Policy
						</Typography>
						<Typography paragraph>
							This privacy policy will explain how OmniumAI (collectively, “OmniumAI”, “we”, “us”, or “our”) collects and uses the personal data we collect from you(“you”, “yours” or “user”) when you use our website. Users are responsible for any third-party data they provide or share through the website and confirm that they have the third-party's consent to provide such data to us.
						</Typography>
					</div>
					<div>
						<Typography variant={"h2"} paragraph>
							Topics
						</Typography>
						<List>
							{topics.map((topic, index) => (
								<ListItemText key={index} primary={topic}/>
							))}
						</List>
					</div>
					<div>
						<Typography variant={"h2"} paragraph>
							What data do we collect?
						</Typography>
						<Typography paragraph>
							Usage Information such as browser type you use, hardware model, operating system, IP address, unique device identifiers, access times, pages viewed, links clicked, time spent, and browsing behavior.
						</Typography>
					</div>
					<div>
						<Typography variant={"h2"} paragraph>
							How do we collect your data?
						</Typography>
						<Typography paragraph>
							We partner with Microsoft Clarity and Microsoft Advertising to capture how you use and interact with our website through behavioral metrics, heatmaps, and session replay to improve and market our products
						</Typography>
					</div>
					<div>
						<Typography variant={"h2"} paragraph>
							How will we use your data?
						</Typography>
						<Typography paragraph>
							Facilitate and improve your online experience, and
							monitor and analyze trends, usage, and activities.
						</Typography>
					</div>
					<div>
						<Typography variant={"h2"} paragraph>
							How do we store your data?
						</Typography>
						<Typography paragraph>
							OmniumAI stores user information in a secure way using Microsoft Clarity and Microsoft Advertising for legitimate business purposes, including to meet our legal, regulatory, or other compliance obligations.
						</Typography>
					</div>
					<div>
						<Typography variant={"h2"} paragraph>
							What are your data protecting rights?
						</Typography>
						<Typography paragraph>
							OmniumAI would like to make sure you are fully aware of all of your data protection rights. Every user is entitled to the following:
						</Typography>
						<List>
							{rights.map((topic, index) => (
								<ListItemText key={index} primary={topic}/>
							))}
						</List>
						<Typography paragraph>
							If you make a request, we have one month to respond to you. If you would like to exercise any of these rights, please contact us at our email: omniumai@omniumai.com
						</Typography>
					</div>
					<div>
						<Typography variant={"h2"} paragraph>
							What are cookies?
						</Typography>
						<Typography paragraph>
							Cookies are text files placed on your computer to collect standard Internet log information and visitor behavior information. When you visit our websites, we may collect information from you automatically through cookies or similar technology. For further information, visit allaboutcookies.org.
						</Typography>
					</div>
					<div>
						<Typography variant={"h2"} paragraph>
							How do we use cookies?
						</Typography>
						<Typography paragraph>
							Understanding how you use our website and distinguishing humans from bots.
						</Typography>
					</div>
					<div>
						<Typography variant={"h2"} paragraph>
							What types of cookies do we use?
						</Typography>
						<Typography paragraph>
							Statistics and Functionality - OmniumAI uses these cookies so that we recognize you on our website and remember your previously selected preferences. These could include what cookies you accepted. First-party and third-party cookies are used.
						</Typography>
					</div>
					<div>
						<Typography variant={"h2"} paragraph>
							How to manage cookies?
						</Typography>
						<Typography paragraph>
							You can set your browser not to accept cookies, and the above website tells you how to remove cookies from your browser. However, in a few cases, some of our website features may not function as a result.
						</Typography>
					</div>
					<div>
						<Typography variant={"h2"} paragraph>
							Privacy policies of other websites
						</Typography>
						<Typography paragraph>
							The OmniumAI website contains links to other websites. Our privacy policy applies only to our website, so if you click on a link to another website, you should read their privacy policy.
						</Typography>
					</div>
					<div>
						<Typography variant={"h2"} paragraph>
							Changes to our privacy policy
						</Typography>
						<Typography paragraph>
							OmniumAI keeps its privacy policy under regular review and places any updates on this web page. This privacy policy was last updated on 19 June 2023.
						</Typography>
					</div>
					<div>
						<Typography variant={"h2"} paragraph>
							How to contact us
						</Typography>
						<Typography paragraph>
							If you have questions about OmniumAI's privacy policy, the data we hold on you, or you would like to exercise one of your data protection rights, please do not hesitate to contact us. Email us at: omniumai@omniumai.com
						</Typography>
					</div>
				</Stack>
			</Container>
		</>
	)
}