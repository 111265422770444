import TrenchWoff from '../../fonts/Trench-Medium.woff';
import TrenchTtf from '../../fonts/trench300.ttf';
import TrenchOtf from '../../fonts/trench300.otf';

// ----------------------------------------------------------------------

export default function MuiCssBaseline() {
  return {
    MuiCssBaseline: {
      styleOverrides: `
        @font-face {
    font-display: swap;
    font-family: 'Trench';
    font-style: normal;
    font-weight: 300;
    src: local('Trench'),
    url(${TrenchWoff}) format('woff'),
    url(${TrenchTtf}) format('truetype'),
    url(${TrenchOtf}) format('opentype');
    unicode-range: U+000-5FF;
}
      `,
    },
  };
}