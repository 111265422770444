import * as Klaro from "klaro"
import Cookies from 'js-cookie';


export default function CookiesBanner() {

    // The config is fully encoded in the JSON language files.
    const config = {
        "cookieName": "OmniumAICookieConsent",
        "cookieExpiresAfterDays": 365,
        "storageMethod": "cookie",
        "mustConsent": false,
        "hideDeclineAll": false,
        "disablePoweredBy": true,
        "acceptAll": false,
        "lang" : "en",
        "noAutoLoad": false,
        "version": 1,
        "translations": {
            "zz": {
                "privacyPolicyUrl": "/privacy"
            },
            "en": {
                "privacyPolicyUrl": "/privacy",
                "MSClarity": {
                    "title": "Microsoft Clarity"
                },
                "consentNotice":{
                    "description":
                        "Hi! Could we please enable some additional services? You can always change or withdraw your consent later."
                }
            }
        },
        "services": [
            {
                "name": "MSClarity",
                "default": true,
                "contextualConsentOnly": false,
                "purposes": [
                    "analytics"
                ]
            }
        ]
    }

    // we assign the Klaro module to the window, so that we can access it in JS
    window.klaro = Klaro;
    window.klaroConfig = config;

    // we set up Klaro with the config
    Klaro.setup(config);

    // get the consent manager
    let manager = Klaro.getManager();

    // we attach an event listener to the consentUpdated event to get the consent string when it is updated
    manager.watch({
        update: function(manager, eventType){
            if (eventType === 'saveConsents') {

                // if the user has consented, we can tell MS Clarity to start tracking the user
                let consents = JSON.parse(Cookies.get('OmniumAICookieConsent'))
                console.log(consents)

                if (consents['MSClarity']) {
                    window.clarity('consent');
                }
            }
        },
    })

    return false;
}